import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import { graphql } from "gatsby";
import logo from "/src/images/iso_color.png";
import micro3D from "/src/images/open-source/micro3D.jpg";
import medShield from "/src/images/open-source/medShield.jpg";
import kohnspirator from "/src/images/open-source/kohnspirator.jpg";
import { Grid } from "@mui/material";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const OpenSource = ({ data, location }) => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Open Source | Custom Surgical" />
      <Grid container xs={12} className="source">
        <h1 className="text_over_open_source">
          Open Source
          <Grid item xs={12} sm={8}>
            <div
              style={{
                fontSize: "25px",
                marginTop: "1em",
                color: "white",
                fontWeight: "500",
              }}
            >
              We are on a mission to democratize medical equipment and make it
              more accessible. Therefore, we share some of our developments
              totally for free. You can download 3D-print files for these
              projects and print them with any 3D-printer in your area. We hope
              that we can help you in your amazing work of improving and saving
              human lives
            </div>
          </Grid>{" "}
        </h1>
      </Grid>
      <div
        style={{
          maxWidth: "1200px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "6em",
        }}
      >
        <div className="container3" id="footer_page_flex">
          <div id="footer_page_width1">
            <h1 style={{ marginTop: "0", marginRight: "1em" }}>
              We strive to better world
            </h1>
          </div>
          <div id="footer_page_width2">
            <p>
              We would appreciate it if you could share and publicize
              information about our open-source projects. This could let more
              people access them but can also motivate our development team for
              new product designs.
            </p>
            <p className="container3 reveal fade-left">
              Our Instagram account for tagging and sharing is{" "}
              <a
                className="links_post"
                href="https://www.instagram.com/customsurgical/"
              >
                @customsurgical
              </a>
            </p>
            <p className="container3 reveal fade-left" id="Micro3D"></p>
            <p className="container3 reveal fade-left" id="Micro3D">
              Feel free to order our ready-to-use 3D-print products on{" "}
              <a
                className="links_post"
                href="https://www.shapeways.com/shops/custom-surgical-1?T=1624623264088"
              >
                Shapeways &nbsp;
              </a>
              if you don’t have a 3D-printer.
            </p>
          </div>
        </div>
        <h1 style={{ textAlign: "center", marginBottom: "1.5em" }}>
          Open source projects
        </h1>
        <div
          className="container3"
          id="footer_page_flex"
          style={{
            alignItems: "center",
            marginTop: "5em",
            padding: "10px",
          }}
        >
          <div
            className="container3 reveal fade-left"
            id="source_flex"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={micro3D} id="MedSHIELD" className="source_img"></img>
          </div>
          <div
            className="container3 reveal fade-right"
            id="source_text"
            style={{ width: "100%" }}
          >
            <a name="Micro3D"></a>
            <h1>Micro3D </h1>
            <p>
              This Micro3D kit allows you to place two smartphones
              simultaneously on the microscope binoculars of a Leica microscope
              to record from both oculars and create stereoscopic videos.
            </p>
            <button className="open-source">
              {" "}
              <a
                href="https://github.com/Custom-Surgical-GmbH/micro3d"
                target="_blank"
                style={{ color: "white" }}
              >
                Get it for free
              </a>
            </button>
          </div>
        </div>
        <div
          className="container3"
          id="footer_page_flex"
          style={{
            alignItems: "center",
            marginTop: "5em",
          }}
        >
          <div
            className="container3 reveal fade-left"
            id="source_flex"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={medShield} id="Kohnspirator" className="source_img"></img>
          </div>
          <div
            className="container3 reveal fade-right"
            id="source_text"
            style={{ width: "100%" }}
          >
            <h1>MedSHIELD </h1>
            <p>
              Free 3D-print files that can be printed and attached to a
              snorkeling mask to use it for virus protection.
            </p>
            <button className="open-source">
              {" "}
              <a
                href="https://github.com/Custom-Surgical-GmbH/MedShield-19"
                target="_blank"
                style={{ color: "white" }}
              >
                Get it for free
              </a>
            </button>
          </div>
        </div>
        <div
          className="container3"
          id="footer_page_flex"
          style={{
            alignItems: "center",
            marginTop: "5em",
            marginBottom: "5em",
          }}
        >
          <div
            className="container3 reveal fade-left"
            id="source_flex"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={kohnspirator} className="source_img"></img>
          </div>
          <div
            className="container3 reveal fade-right"
            id="source_text"
            style={{ width: "100%" }}
          >
            <h1>Kohnspirator </h1>
            <p>
              Free designs for 3D-printing to customize a snorkeling mask for
              virus protection - and donate to people in need.
            </p>
            <button className="open-source">
              {" "}
              <a
                href="https://github.com/Custom-Surgical-GmbH/kohnspirator"
                target="_blank"
                style={{ color: "white" }}
              >
                Get it for free
              </a>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OpenSource;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
